
import { formatDate } from '@/utils/date'
import request from '@/utils/request'
import { defineComponent } from 'vue'
import { Download, MessageBox, Printer } from '@element-plus/icons'
import { stringify } from '@/utils/utils'
import { ElMessageBox, ElMessage } from 'element-plus'

const baseUrl = process.env.VUE_APP_API_BASE_URL
export default defineComponent({
	components: { Download, Printer },
	data() {
		const end = new Date()
		const start = new Date()
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
		return {
			defaultDate: [new Date(start), new Date(end)],
			shortcuts: [
				{
					text: '最近1天',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
						return [start, end]
					},
				},
				{
					text: '最近3天',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
						return [start, end]
					},
				},
				{
					text: '最近1周',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
						return [start, end]
					},
				},
				{
					text: '最近1个月',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
						return [start, end]
					},
				},
				{
					text: '最近3个月',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
						return [start, end]
					},
				},
			],
			searchForm: {
				status_exception: void 0,
				keyword: '',
				type: 'firstname',
				code: '',
				status_track: [] as any,
				date: [] as any,
				pay_date: [] as any,
			},
			addressDialog: {
				visible: false,
				package_num: '',
				address: {
					address1: '',
					address2: '',
					city: '',
					country: 'US',
					firstname: '',
					lastname: ' ',
					mobile: '',
					state: '',
					zipcode: '',
				},
			},
			routerDialog: {
				visible: false,
				code: '',
				router: [],
			},
			isLoading: false,
			selectedRows: [],
			downloadLoading: false,
			pageConfig: {
				size: 20,
				current_page: 1,
				total: 0,
			},
			packageList: [],
			validateAddress: {
				firstname: [
					{ required: true, message: '请输入收件人姓名', trigger: 'blur' },
				],
				country: [
					{ required: true, message: '请选择国家或地区', trigger: 'change' },
				],
				zipcode: [{ required: true, message: '请输入邮编', trigger: 'blur' }],
				state: [{ required: true, message: '请选择州', trigger: 'change' }],
				city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
				address1: [{ required: true, message: '请输入地址1', trigger: 'blur' }],
			},
			countryData: {} as any,
			filterStateData: {},
			countryList: [],
			stateList: [] as any[],
			statements: {
				delivered: 0,
				exceptions: 0,
				in_transit: 0,
			},
		}
	},
	async created() {
		const {
			keyword,
			page_size,
			code = '',
			page_index,
			type,
			start_time = 0,
			end_time = 0,
			pay_start_time = 0,
			pay_end_time = 0,
			status_track = '',
		}: any = this.$route.query
		if (page_size) {
			this.searchForm.keyword = keyword
			this.searchForm.code = code
			this.searchForm.type = type
			if (status_track) {
				this.searchForm.status_track = (status_track as string)
					.split(',')
					.map((v) => Number(v))
			}
			this.searchForm.date = Number(start_time)
				? [new Date(start_time * 1000), new Date(end_time * 1000)]
				: null
			this.searchForm.date = Number(pay_start_time)
				? [new Date(pay_start_time * 1000), new Date(pay_end_time * 1000)]
				: null
			this.pageConfig.size = Number(page_size ?? 20)
			this.pageConfig.current_page = Number(page_index ?? 1)
		}
		await this.searchPackages()
		this.getState()
	},
	methods: {
		async searchPackage(type = 0) {
			if (type == 1) {
				this.searchForm.status_track = [1]
			}
			if (type == 2) {
				this.searchForm.status_track = [99]
			}
			if (type == 3) {
				this.searchForm.status_track = [11, 12, 13, 14, 15]
			}
			this.searchPackages()
		},
		async showPackageRouter(row: any) {
			const {
				package_attr: { id, package_attr_num },
			} = row
			if (!id) return false
			const { data } = await this.$axios.get(
				`/v1/merchants/package/${id}/tracking`
			)
			if (!data) return
			this.routerDialog.visible = true
			this.routerDialog.code = package_attr_num
			this.routerDialog.router = data
		},
		handleSelectionChange(val: any) {
			this.selectedRows = val
		},
		async getState() {
			const { data } = await this.$axios.get(
				'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
			)
			this.countryData = data
			this.countryList = Object.values(this.countryData)
		},
		filterState(keyword = '') {
			keyword = keyword.trim()
			if (!keyword) return (this.filterStateData = this.stateList)
			this.filterStateData = this.stateList.filter((item: any) => {
				return (
					item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
					item.id.toLowerCase().indexOf(keyword.toLowerCase()) > -1
				)
			})
		},
		printPackages() {
			const { selectedRows } = this
			const titles = [] as string[]
			const ids = selectedRows.map((item: any) => {
				titles.push(`${item.country}/B`)
				if (item.package_attr && item.package_attr.package_attr_num)
					return item.package_attr.package_attr_num
				return `${item.package_num}-1`
			})
			const baseUrl = process.env.VUE_APP_API_BASE_URL
			window.open(
				`${baseUrl}v1/jobline/generate/package-label?code=${ids.join(
					','
				)}&title=${titles.join(',')}`
			)
		},
		printPackagesWithRemark() {
			const { selectedRows } = this
			if (selectedRows.length > 50) {
				return ElMessage({
					message: '批量打印不能超过50个',
					type: 'warning',
				})
			}
			const titles = [] as string[]
			const remarks = [] as string[]
			const ids = selectedRows.map((item: any) => {
				titles.push(`${item.country}/B`)
				remarks.push(item.remark || '')
				if (item.package_attr && item.package_attr.package_attr_num)
					return item.package_attr.package_attr_num
				return `${item.package_num}-1`
			})
			const baseUrl = process.env.VUE_APP_API_BASE_URL
			window.open(
				`${baseUrl}v1/jobline/generate/package-label?code=${ids.join(
					','
				)}&title=${titles.join(',')}&remark=${remarks.join('|;')}`
			)
		},
		openAddressDialog(row: any) {
			this.addressDialog.package_num = row.package_num
			this.stateList = this.countryData[row.country].states as any[]
			this.filterStateData = this.stateList
			this.addressDialog.address = {
				address1: row.address1,
				address2: row.address2,
				city: row.city,
				country: row.country,
				firstname: row.firstname,
				lastname: row.lastname,
				mobile: row.mobile,
				state: row.state,
				zipcode: row.zipcode,
			}
			this.addressDialog.visible = true
		},
		async savePackageAddress() {
			const isValidate = await (this.$refs['address-form'] as any)
				.validate()
				.catch(() => false)
			if (!isValidate) return
			const { package_num, address } = this.addressDialog
			const { data } = await request
				.put(`/v1/merchants/package/${package_num}`, {
					package_num,
					...address,
				})
				.catch(() => {
					return {
						data: null,
					}
				})
			if (data) {
				ElMessage({
					message: '修改成功',
					type: 'success',
				})
				this.addressDialog.visible = false
				this.searchPackages()
			}
		},
		formatDate(val: number) {
			return formatDate('YYYY-MM-DD h:i:s', val)
		},
		async downloadPackages() {
			const params = this.getCondition()
			window.open(
				`${baseUrl}v1/merchants/package/download?${stringify(params)}`
			)
		},
		async cancelPackage(row: any) {
			const {
				package_num,
				package_attr: { package_attr_num },
			} = row
			const isConfirm = await ElMessageBox.confirm(
				`确认要取消订单(包裹)：${
					package_attr_num ?? package_num
				} 吗？若只有一个包裹将同时取消订单,取消后不可恢复请谨慎操作!`,
				'提示',
				{
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				}
			).catch(() => false)
			if (!isConfirm) return
			const { data } = await request
				.delete(`/v1/merchants/package/${package_attr_num ?? package_num}`)
				.catch(() => {
					return {
						data: null,
					}
				})
			if (data) {
				ElMessage({
					message: '取消成功',
					type: 'success',
				})
				this.searchPackages()
			}
		},
		handelSizeChange(val: number) {
			this.pageConfig.size = val
			this.searchPackages(true)
		},
		async searchPackages(isReset = false) {
			if (isReset) {
				this.pageConfig.current_page = 1
			}
			if (this.isLoading) return
			const params = {
				page_size: this.pageConfig.size,
				...this.getCondition(),
				page_index: this.pageConfig.current_page,
				keyword: this.searchForm.keyword,
				code: this.searchForm.code,
				type: this.searchForm.type,
			}
			this.isLoading = true
			this.selectedRows = []
			const { data } = await request
				.get('/v1/merchants/package/list', {
					params: params,
				})
				.catch(() => {
					return {
						data: null,
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.isLoading = false
					}, 500)
				})
			await this.getStatements().catch()
			if (!data) return
			this.packageList = data.list
			this.pageConfig.total = Number(data.page.total)
			// this.$router
			// 	.push({
			// 		query: params,
			// 	})
			// 	.catch((err) => err)
		},
		getCondition() {
			let startTime = 0
			let endTime = 0
			let payStartTime = 0
			let payEndTime = 0
			const {
				keyword,
				code,
				type,
				date,
				pay_date,
				status_track,
				status_exception,
			} = this.searchForm
			if (date && date.length) {
				const [start, end] = date
				startTime = Math.round(new Date(start).getTime() / 1000)
				endTime = Math.round(new Date(end).getTime() / 1000) + 86400
			}
			if (pay_date && pay_date.length) {
				console.log(1)
				const [start, end] = pay_date
				payStartTime = Math.round(new Date(start).getTime() / 1000)
				payEndTime = Math.round(new Date(end).getTime() / 1000) + 86400
			}
			console.log(payStartTime)
			let tracks = []
			for (const v of status_track) {
				if (99 == v) {
					tracks.push(...[4, 5, 6, 7, 8, 9, 10])
					continue
				}
				tracks.push(v)
			}
			if (
				status_exception &&
				(status_exception == 1 || status_exception == 2)
			) {
				tracks = [11, 12, 13, 14, 15]
				this.searchForm.status_track = tracks
			}
			return {
				start_time: startTime,
				end_time: endTime,
				pay_start_time: payStartTime,
				pay_end_time: payEndTime,
				keyword,
				code,
				type,
				status_track: tracks.join(','),
				status_exception,
			}
		},
		async getStatements() {
			const params = {
				page_size: this.pageConfig.size,
				...this.getCondition(),
				page_index: this.pageConfig.current_page,
				keyword: this.searchForm.keyword,
				code: this.searchForm.code,
				type: this.searchForm.type,
			}
			const { data } = await request
				.get('/v1/merchants/package/statements', {
					params: params,
				})
				.catch(() => {
					return {
						data: null,
					}
				})
			if (!data) return
			this.statements.delivered = data.delivered || 0
			this.statements.exceptions = data.exceptions || 0
			this.statements.in_transit = data.in_transit || 0
		},
		async handlingExceptions(row: any) {
			const {
				package_attr: { package_attr_num },
			} = row
			const isConfirm = await ElMessageBox.confirm(
				`确认要将包裹:${package_attr_num} 标记为已处理吗？`,
				'提示',
				{
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				}
			).catch(() => false)
			if (!isConfirm) return
			const { data } = await request
				.post(`/v1/merchants/package/process-exception/${package_attr_num}`)
				.catch(() => {
					return {
						data: null,
					}
				})
			if (data) {
				ElMessage({
					message: '操作成功',
					type: 'success',
				})
				this.searchPackages()
			}
		},
	},
})
